@if (loading) {
<div class="loading">
  <mat-spinner [diameter]="60"></mat-spinner>
</div>
}@else if (data) {
<div class="page-content">
  <div class="no">
    <h2>{{ data.no }}</h2>
    <span class="date" matTooltip="Date Added">{{
      data.updated_time | date
    }}</span>
  </div>
  <h1>{{ data.name }}</h1>
  <div class="tags">
    @if (data.fabric) {
    <div class="tag">{{ data.fabric }}</div>
    }
    <!-- @if (data.weight>0) {
    <div class="tag">{{ data.weight }} g</div>
    } -->
  </div>
</div>
<app-product-images-swiper [images]="data.images"></app-product-images-swiper>
<mat-divider></mat-divider>
<div class="page-content options">
  @if (data.description) {
  <p>{{ data.description }}</p>
  }
  <div class="attrs">
    @for (attr of data.attrs; track $index) {
    <div class="attr">
      <h3>{{ attr.name }}</h3>
      <div class="values">
        @for (value of attr.values; track $index) {
        <app-product-attr-value-item
          matRipple
          matRippleColor="#ffd9e166"
          [data]="value"
          [ngClass]="{ active: selectedAttr[attr.id] === value.id }"
          (click)="selectAttrValue(attr, value.id)"
        ></app-product-attr-value-item>
        }
      </div>
    </div>
    }
    <div class="priced-attr">
      <h3>
        {{ data.priced_attr.name }}
        <span>(Prices exclude shipping costs.)</span>
      </h3>
      <div class="values">
        @for (value of data.priced_attr.values; track $index) {
        <app-product-priced-attr-value-item [data]="value">
          <app-quantity-input [(ngModel)]="value.quantity"></app-quantity-input>
        </app-product-priced-attr-value-item>
        }
      </div>
    </div>
  </div>
</div>
<mat-divider></mat-divider>
<div class="page-content">
  <!-- <div class="actions">
    <mat-form-field appearance="outline">
      <mat-label>Quantity</mat-label>
      <input
        matInput
        type="number"
        [min]="1"
        [step]="1"
        [max]="1000000"
        inputmode="numeric"
        pattern="\d*"
        [(ngModel)]="quantity"
        (ngModelChange)="updateParams()"
      />
      @if (quantity>=1) {
      <mat-icon matSuffix fontSet="material-icons-outlined">mood</mat-icon>
      }@else{
      <mat-icon matSuffix fontSet="material-icons-outlined">mood_bad</mat-icon>
      }
    </mat-form-field>
  </div> -->
  <div class="actions">
    <span class="price">
      $
      <span>{{ subtotal }}</span>
    </span>

    <button mat-flat-button (click)="addToCart()" [disabled]="!subtotal">
      @if (cartLoading) {
      <mat-spinner [diameter]="36"></mat-spinner>
      }@else {
      <span>Add to Cart</span>
      }
    </button>
  </div>
</div>
}@else {
<div class="not-found">
  <h1>WHOOPS</h1>
  <p>
    It looks like something has gone wrong, either the link is broken or the
    page has been removed from Super Apparel.
  </p>
  <button mat-stroked-button routerLink="/" [replaceUrl]="true">
    Back to Home
  </button>
</div>
}
