import { Component } from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-quantity-input',
  standalone: true,
  imports: [MatIconModule, MatButtonModule, FormsModule],
  templateUrl: './quantity-input.component.html',
  styleUrl: './quantity-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: QuantityInputComponent,
    },
  ],
})
export class QuantityInputComponent implements ControlValueAccessor {
  value: string = '0';

  decrement() {
    var r = parseInt(this.value) || 0;
    if (r > 0) {
      r = r - 1;
    }
    this.value = r.toString();
    this.onValueChanged();
  }

  increment() {
    var r = parseInt(this.value) || 0;
    r = r + 1;
    this.value = r.toString();
    this.onValueChanged();
  }

  onValueChanged() {
    const r = parseInt(this.value) || 0;
    this.onFormChange(r);
  }

  writeValue(v: number) {
    this.value = v ? v.toString() : '0';
  }

  onFormChange = (v: any) => {};

  onFormTouched = () => {};

  registerOnChange(onChange: any) {
    this.onFormChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onFormTouched = onTouched;
  }

  disabled: boolean = false;
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
