import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class ShoppingApiService {
  constructor(private http: HttpService) {}
  buildurl(path: string, queryMap: any = null): string {
    return this.http.buildurl('/shopping' + path, queryMap);
  }

  findShoppingCartItems() {
    const url = this.buildurl('/cart/items');
    return this.http.fget(url);
  }

  addShoppingCartItem(
    productID: number,
    attrValueIDs: number[],
    pricedAttrValueID: number,
    quantity: number
  ) {
    const url = this.buildurl(`/cart/item`);
    const body = {
      product_id: productID,
      attr_value_ids: attrValueIDs,
      priced_attr_value_id: pricedAttrValueID,
      quantity,
    };
    return this.http.post(url, body);
  }

  addShoppingCartItems(
    items: {
      product_id: number;
      attr_value_ids: number[];
      priced_attr_value_id: number;
      quantity: number;
    }[]
  ) {
    const url = this.buildurl(`/cart/items`);
    const body = {
      items,
    };
    return this.http.post(url, body);
  }

  updateUserShoppingCartItem(id: number, quantity: number) {
    const url = this.buildurl(`/cart/item/${id}`);
    const body = { quantity };
    return this.http.put(url, body);
  }

  deleteUserShoppingCartItem(id: number) {
    const url = this.buildurl(`/cart/item/${id}`);
    return this.http.delete(url);
  }

  createUserOrderFromCart({
    addrID,
    remark,
  }: {
    addrID: number;
    remark: string;
  }) {
    const url = this.buildurl(`/order`);
    const body = {
      addr_id: addrID,
      remark,
    };
    return this.http.post(url, body);
  }

  getUserOrder(id: string) {
    const url = this.buildurl(`/order/${id}`);
    return this.http.fget(url);
  }
  findUserOrders({
    status,
    page,
    pageSize,
  }: {
    status: string;
    page: number;
    pageSize: number;
  }) {
    const url = this.buildurl(`/orders`, { status, page, page_size: pageSize });
    return this.http.fget(url);
  }

  cancelUserOrder(id: string) {
    const url = this.buildurl(`/order/${id}/cancel`);
    return this.http.put(url, {});
  }
}
