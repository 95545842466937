<a mat-icon-button (click)="decrement()">
  <mat-icon>remove</mat-icon>
</a>
<input
  type="number"
  min="0"
  step="1"
  max="10000"
  inputmode="numeric"
  pattern="\d*"
  onkeydown="return event.key === 'Backspace' || event.key === 'Tab' || /^[0-9]$/.test(event.key)"
  [(ngModel)]="value"
  (ngModelChange)="onValueChanged()"
/>
<a mat-icon-button (click)="increment()">
  <mat-icon>add</mat-icon>
</a>
