import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(@Inject(DOCUMENT) private doc: any) {}

  createLinkForCanonicalURL() {
    const url = this.doc.URL.replace(
      'www.superapparel.online',
      'superapparel.online'
    ).replace('http://', 'https://');
    var link = this.doc.querySelector("link[rel='canonical']");
    if (!link) {
      link = this.doc.createElement('link');
      this.doc.head.appendChild(link);
    }
    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', url);
  }
}
