@if (data.image) {
<img
  ngSrc="{{ data.image | fileurl }}"
  width="80"
  height="80"
  alt="attr image"
/>
}
<span class="name">{{ data.name | titlecase }}</span>
<span class="price">
  $
  <span>{{ data.price | number : ".1" }}</span></span
>

<ng-content></ng-content>
